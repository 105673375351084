<template>
  <div class="body-bg"></div>
  <div class="con">
    <div class="i-top">
      <nut-row>
        <nut-col :span="24">
          <nut-col :span="18">
            <div style="font-size: 23px; margin: 20px; color: #fff">
              SView3d <br/>协同平台企业版
            </div>
          </nut-col>
          <nut-col :span="6">
            <img
                :src="image.logo"
                width="40"
                style="margin-top: 25px; float: right; margin-right: 20px"
            /></nut-col
          >
        </nut-col>
      </nut-row>
      <div width="80%" class="invite-top-text">
        SView
        APP企业版是面向企业用户的专业三维可视化协同解决方案，为用户提供40余种CAD格式图纸浏览、
        文字音频站内交流、项目管理、安全分享、文件存储等功能，实现企业内部及上下游产业链的高效协作。
      </div>
    </div>
    <div class="blank10"></div>
    <div class="box-item" v-for="item in boxList" :key="item.id">
      <h3>{{ item.title }}</h3>
      <p>
        {{ item.text }}
      </p>
    </div>
    <div class="blank10"></div>
    <div class="video-box">
      <nut-video :source="source" :options="options"></nut-video>
    </div>
    <div class="blank10"></div>
    <div class="re-text">
      注册即可拥有<br/>
      <a :href="invitationCode.url+invitationCode.code" style="color: azure;text-decoration: none">https://work.sview3d.com/phoneWeb/</a><br/>
      扫码注册
    </div>
    <!--    <div class="qr-box"><img :src="image.qrcode"/></div>-->
    <div class="qr-box">
      <vue-qr :text="qrVue.text"
              :dotScale="qrVue.dotScale"
              :logoScale="qrVue.logoScale"
              :logoSrc="qrVue.logoSrc"
              :size="qrVue.size"
              :colorDark="qrVue.colorDark"
              :colorLight="qrVue.colorLight"
              :margin="qrVue.margin"
      ></vue-qr>
      <!--旧二维码 隐藏-->
      <div style="display:none" class="codeImg" id="qrCode"></div>
    </div>
  </div>
</template>
<script>
import {defineComponent, toRefs, reactive, onMounted, getCurrentInstance} from "vue";
import QRCode from 'qrcodejs2';
import {useRoute} from "vue-router";
import wx from 'weixin-js-sdk';
import vueQr from 'vue-qr/src/packages/vue-qr.vue';

export default defineComponent({
  name: "invite",
  components: {
    vueQr
  },
  setup() {
    const route = useRoute();
    const {proxy} = getCurrentInstance();
    const boxList = reactive([
      {
        id: "1",
        title: "超高性能平替CAD",
        text:
            " SView企业版支持包括SolidWorks、ProE、UG等40余种主流二/三维CAD文件格式、支持图纸格式转换，降低企业对各类CAD软件高额购买费用。",
      },
      {
        id: "2",
        title: "工程微信沟通闭环",
        text:
            "SView企业版支持在线协同、项目管理、安全分享、文件存储等功能，提供文字、语音、视频、文件内部传输，优化图纸分享、转发、批复流程，提升工作效率。",
      },
      {
        id: "3",
        title: "权限严格管控、保护图纸安全",
        text:
            "管理员可根据员工不同的岗位进行图纸操作权限划分，设定分享权限，并可在后台查阅图纸浏览记录，避免数据泄露，让图纸保存更为安全。",
      },
      {
        id: "4",
        title: "租用模式灵活、超低价格",
        text:
            "SView企业版即买即用，上手就会；企业版划分多种价格区间，低至千元；租用模式灵活，可先进行七天免费全功能试用，避免试错成本。",
      },
    ]);
    const image = reactive({
      bigBg: require("@/assets/img/share/big-bg.png"),
      bigBoxbg: require("@/assets/img/share/big-boxbg.png"),
      leftCirl: require("@/assets/img/share/left-cirl.png"),
      logo: require("@/assets/img/share/logo.png"),
      qrcode: require("@/assets/img/share/qrcode.png"),
      rtCirle: require("@/assets/img/share/rt-cirle.png"),
      smallBg: require("@/assets/img/share/small-bg.png"),
      smallBoxbg: require("@/assets/img/share/small-boxbg.png"),
      topPic: require("@/assets/img/share/top-pic.jpg"),
      enterpriseLogo: require("@/assets/img/enterprise-logo.jpg"),
      logoTwo: require("@/assets/img/share/logo2.png"),
    });
    const state = reactive({
      source: {
        src: require("@/assets/video/video.mp4"),
        type: "video/mp4",
      },
      options: {
        autoplay: true,
        muted: false,
        controls: true,
        loop: true
      },
    });
    //注册地址与邀请码
    const invitationCode = reactive({
      url: 'https://work.sview3d.com/phoneWeb/register?invitationCode=',
      code: route.query.invitationCode !== undefined ? route.query.invitationCode : '',
    })
    //生成二维码（无logo）
    const getQC = () => {
      let qrcode = new QRCode('qrCode', {
        width: 150,
        height: 150, // 高度
        text: invitationCode.url + invitationCode.code, // 要生成二维码的链接（或当前页面地址）
        colorDark: '#000',
        colorLight: '#fff',
      })
      console.log(qrcode, `${location.origin}${location.pathname}`)
    };
    //设置二维码参数（有logo的）
    const qrVue = reactive({
      //欲编码的内容,即URL地址
      text: invitationCode.url + (route.query.invitationCode !== undefined ? route.query.invitationCode : ''),
      //数据区域点缩小比例,默认为1
      dotScale: '0.5',
      //用于计算 LOGO 大小的值, 过大将导致解码失败, LOGO 尺寸计算公式 logoScale*(size-2*margin), 默认 0.2
      logoScale: '0.2',
      //嵌入至二维码中心的 LOGO 地址
      logoSrc: 'https://work.sview3d.com/phoneWeb/img/enterprise-logo.a49cf6d4.jpg',
      //尺寸, 长宽一致, 包含外边距
      size: 1000,
      //实点的颜色
      colorDark: '#000',
      //空白区的颜色
      colorLight: '#fff',
      //二维码图像的外边距, 默认 20px
      margin: 0,
    })
    //在微信内部网页分享，单页面分享
    const wxShare = () => {
      proxy.$axios.get("https://service.sview3d.com/jssdk?url=" + location.href.split('#')[0])
          .then(function (result) {
            console.log("微信分享返回结果，", result.data)
            if (result.data !== undefined) {
              const data1 = result.data;
              console.log("微信分享")
              //权限验证配置
              wx.config({
                debug: false, // 开启调试模式,调用的所有api的返回值会在客户端alert出来，若要查看传入的参数，可以在pc端打开，参数信息会通过log打出，仅在pc端时才会打印。
                appId: data1.appId, // 必填，公众号的唯一标识
                timestamp: data1.timestamp, // 必填，生成签名的时间戳
                nonceStr: data1.nonceStr, // 必填，生成签名的随机串
                signature: data1.signature,// 必填，签名
                jsApiList: ['updateAppMessageShareData', 'updateTimelineShareData'] // 必填，需要使用的JS接口列表
              });
              //成功回调
              wx.ready(function () {
                // config信息验证后会执行ready方法，所有接口调用都必须在config接口获得结果之后，config是一个客户端的异步操作，所以如果需要在页面加载时就调用相关接口，则须把相关接口放在ready函数中调用来确保正确执行。对于用户触发时才调用的接口，则可以直接调用，不需要放在ready函数中。
                //分享给朋友
                wx.updateAppMessageShareData({
                  title: '随心所阅，畅览3D', // 分享标题
                  desc: '高效查看40种CAD，多终端登录协同互动，整合人员/项目/图纸管理', // 分享描述
                  link: location.href, // 分享链接，该链接域名或路径必须与当前页面对应的公众号JS安全域名一致
                  imgUrl: 'https://work.sview3d.com/phoneWeb/img/enterprise-logo.a49cf6d4.jpg', // 分享图标
                  success: function () {
                    // 设置成功
                    console.log("微信朋友分享success")
                  }
                })
                //分享到朋友圈
                wx.updateTimelineShareData({
                  title: '随心所阅，畅览3D', // 分享标题
                  link: location.href, // 分享链接，该链接域名或路径必须与当前页面对应的公众号JS安全域名一致
                  imgUrl: 'https://work.sview3d.com/phoneWeb/img/enterprise-logo.a49cf6d4.jpg', // 分享图标
                  success: function () {
                    console.log("微信朋友圈分享success")
                  }
                })
              });
              //错误回调
              wx.error(function (res) {
                // config信息验证失败会执行error函数，如签名过期导致验证失败，具体错误信息可以打开config的debug模式查看，也可以在返回的res参数中查看，对于SPA可以在这里更新签名。
                console.log("微信分享失败，", res)
              });
            }
          });
    }
    onMounted(() => {
      getQC();
      wxShare();
    });
    return {
      ...toRefs(state),
      image,
      boxList,
      invitationCode,
      qrVue,
    };
  },
});
</script>
